








import { Component, Vue } from 'vue-property-decorator';
import WorkOrderForm from '@/shared/components/wo/WorkOrderForm.vue';

import { WoType } from '@/shared/components/wo/models/index';

@Component({
  components: { WorkOrderForm }
})
export default class WOImport extends Vue {
  WoType = WoType;

  woType: WoType = WoType.ImportRegular;

  constructor() {
    super();
    this.woType = this.$route.params.type as WoType;
  }

  get title(): string {
    switch (this.$route.params.type as WoType) {
      case WoType.ImportRegular:
        return 'Import - REG';
      case WoType.ImportShuttle:
        return 'Import - Shuttle';
      case WoType.ExportShuttle:
        return 'Export - Shuttle';
      case WoType.ExportRegular:
        return 'Export - REG';
      case WoType.VanRegular:
        return 'VAN';
      case WoType.CyToCy:
        return 'CY TO CY';
      case WoType.BareChassis:
        return 'Bare Chassis';
      default:
        return this.$route.params.type;
    }
  }
}
